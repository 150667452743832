import { makeAutoObservable } from "mobx"

export interface pdfMetaStoreModel {
    initPdfMeta(id: string, pdfUrl: string, thumbnailUrl: string): void,
    isInitialized(id: string): boolean,
    getHasVariableSize: (id: string) => boolean | null,
    getIsCached(id: string): boolean,
    getLastCompensatedScale: (id: string) => number,
    getLoaded: (id: string) => boolean | null,
    getNumPage: (id: string) => number | null,
    getPageHeights: (id: string) => number[] | null,
    getPagePos: (id: string) => number[] | null,
    getPageWidths: (id: string) => number[] | null,
    getPinchStartDistance: (id: string) => number | null,
    getPinchStartScale: (id: string) => number,
    getPrevScale: (id: string) => number,
    getScale: (id: string) => number,
    getScaleAnchor: (id: string) => {x: number, y: number}|null
    getStaticPageHeights: (id: string) => number[] | null,
    getStaticPageWidths: (id: string) => number[] | null,
    setHasVariableSize(id: string, hasVariableSize: boolean | null): void,
    setIsCached(id: string, isCached: boolean): void,
    setLastCompensatedScale(id: string, prevScale: number): void,
    setLoaded(id: string, loaded: boolean): void,
    setNumPages(id: string, numPages: number): void,
    setPageHeights(id: string, pageHeights: number[]): void,
    setPagePos(id: string, pagePos: number[]): void,
    setPageWidths(id: string, pageWidths: number[]): void,
    setPinchStartDistance(id: string, pinchStartDistance: number|null): void,
    setPrevScale(id: string, prevScale: number): void,
    setScale(id: string, scale: number): void,
    setScaleAnchor(id: string, scaleAnchor: {x: number, y: number}|null): void,
    setStaticPageHeights(id: string, pageHeights: number[]): void,
    setStaticPageWidths(id: string, pageWidths: number[]): void,
    updatePinchStartScale(id: string): void,
}

type PdfMeta = {
  hasVariableSize: boolean | null,
  id: string,
  isCached: boolean,
  lastCompensatedScale: number,
  loaded: boolean,
  numPages: number,
  pageHeights: number[],
  pagePos: number[],
  pageWidths: number[],
  pdfUrl: string,
  pinchStartDistance: number|null,
  pinchStartScale: number,
  prevScale: number,
  scale: number,
  scaleAnchor: {x: number, y: number}|null
  staticPageHeights: number[],
  staticPageWidths: number[],
  thumbnailUrl: string
}


class pdfMetaStore {
    pdfMetaList: PdfMeta[] = []
    minScale = 0.5
    maxScale = 2.5

    constructor() {
      makeAutoObservable(this)
    }

    getPdfMeta(id: string) {
      if(id) {
        const pdfMeta = this.pdfMetaList.find(meta => meta.id === id)
        if(pdfMeta) return pdfMeta
      }
      return null
    }

    isInitialized(id: string) {
      const pdfMeta = this.getPdfMeta(id)
      if(pdfMeta) return true
      else return false
    }

    initPdfMeta(id: string, pdfUrl: string, thumbnailUrl: string) {
      // check if meta already exists
      if(this.isInitialized(id)) {
        console.warn("PdfMeta already exists")
        return
      }
      // init pdfMeta with default values
      const pdfMeta: PdfMeta = {
        hasVariableSize: null,
        id: id,
        isCached: false,
        lastCompensatedScale: 1,
        numPages: 0,
        loaded: false,
        pagePos: [0, 0, 0],
        pageHeights: [],
        pageWidths: [],
        pdfUrl: pdfUrl,
        pinchStartDistance: null,
        pinchStartScale: 1,
        prevScale: 1,
        scale: 1,
        scaleAnchor: null,
        staticPageHeights: [],
        staticPageWidths: [],
        thumbnailUrl: thumbnailUrl
      }
      this.pdfMetaList.push(pdfMeta)
    }

    getHasVariableSize(id: string) {
      const pdfMeta = this.getPdfMeta(id)
      if(pdfMeta) return pdfMeta.hasVariableSize
      else return null
    }

    getIsCached(id: string) {
      const pdfMeta = this.getPdfMeta(id)
      if(pdfMeta) return pdfMeta.isCached
      else return false
    }

    getLastCompensatedScale(id: string) {
      const pdfMeta = this.getPdfMeta(id)
      if(pdfMeta) return pdfMeta.lastCompensatedScale
      else return 1
    }

    getLoaded(id: string) {
      const pdfMeta = this.getPdfMeta(id)
      if(pdfMeta) return pdfMeta.loaded
      else return null
    }

    getNumPage(id: string) {
      const pdfMeta = this.getPdfMeta(id)
      if(pdfMeta) return pdfMeta.numPages
      else return null
    }

    getPageHeights(id: string) {
      const pdfMeta = this.getPdfMeta(id)
      if(pdfMeta) return pdfMeta.pageHeights
      else return []
    }

    getPagePos(id: string) {
      const pdfMeta = this.getPdfMeta(id)
      if(pdfMeta) return pdfMeta.pagePos
      else return null
    }

    getPageWidths(id: string) {
      const pdfMeta = this.getPdfMeta(id)
      if(pdfMeta) return pdfMeta.pageWidths
      else return []
    }

    getPinchStartDistance(id: string) {
      const pdfMeta = this.getPdfMeta(id)
      if(pdfMeta) return pdfMeta.pinchStartDistance
      else return null
    }

    getPinchStartScale(id: string) {
      const pdfMeta = this.getPdfMeta(id)
      if(pdfMeta) return pdfMeta.pinchStartScale
      else return 1
    }

    getPrevScale(id: string) {
      const pdfMeta = this.getPdfMeta(id)
      if(pdfMeta) return pdfMeta.prevScale
      else return 1
    }

    getScale(id: string) {
      const pdfMeta = this.getPdfMeta(id)
      if(pdfMeta) return pdfMeta.scale
      else return 1
    }

    getScaleAnchor(id: string) {
      const pdfMeta = this.getPdfMeta(id)
      if(pdfMeta) return pdfMeta.scaleAnchor
      else return null
    }

    getStaticPageHeights(id: string) {
      const pdfMeta = this.getPdfMeta(id)
      if(pdfMeta) return pdfMeta.staticPageHeights
      else return null
    }

    getStaticPageWidths(id: string) {
      const pdfMeta = this.getPdfMeta(id)
      if(pdfMeta) return pdfMeta.staticPageWidths
      else return null
    }

    setHasVariableSize(id: string, hasVariableSize: boolean | null) {
      const pdfMeta = this.getPdfMeta(id)
      if(pdfMeta) pdfMeta.hasVariableSize = hasVariableSize
    }

    setIsCached(id: string, isCached: boolean) {
      const pdfMeta = this.getPdfMeta(id)
      if(pdfMeta) pdfMeta.isCached = isCached
    }

    setLastCompensatedScale(id: string, lastCompensatedScale: number) {
      const pdfMeta = this.getPdfMeta(id)
      if(pdfMeta) pdfMeta.lastCompensatedScale = lastCompensatedScale
    }

    setLoaded(id: string, loaded: boolean) {
      const pdfMeta = this.getPdfMeta(id)
      if(pdfMeta) pdfMeta.loaded = loaded
    }

    setNumPages(id: string, numPages: number) {
      const pdfMeta = this.getPdfMeta(id)
      if(pdfMeta) pdfMeta.numPages = numPages
    }

    setPageHeights(id: string, pageHeights: number[]) {
      const pdfMeta = this.getPdfMeta(id)
      if(pdfMeta) pdfMeta.pageHeights = pageHeights
    }

    setPagePos(id: string, pagePos: number[]) {
      const pdfMeta = this.getPdfMeta(id)
      if(pdfMeta) pdfMeta.pagePos = pagePos
    }

    setPageWidths(id: string, pageWidths: number[]) {
      const pdfMeta = this.getPdfMeta(id)
      if(pdfMeta) pdfMeta.pageWidths = pageWidths
    }

    setPinchStartDistance(id: string, pinchStartDistance: number|null) {
      const pdfMeta = this.getPdfMeta(id)
      if(pdfMeta) pdfMeta.pinchStartDistance = pinchStartDistance
    }

    setPrevScale(id: string, prevScale: number) {
      const pdfMeta = this.getPdfMeta(id)
      if(pdfMeta) pdfMeta.prevScale = prevScale
    }

    setScale(id: string, scale: number) {
      const pdfMeta = this.getPdfMeta(id)
      if(pdfMeta && scale) {
        // round scale to two decimals
        let nextScale = Math.round(scale * 100) / 100
        // always reach min and max values
        if(nextScale > this.maxScale) nextScale = this.maxScale
        if(nextScale < this.minScale) nextScale = this.minScale
        const prevScale = pdfMeta.scale
        const pageHeights = pdfMeta.pageHeights
        const pageWidths = pdfMeta.pageWidths

        if(Array.isArray(pageHeights) && Array.isArray(pageWidths)&& nextScale && prevScale && nextScale !== prevScale) {
          // calculate the height and width of the pages according to their new scaling
          const heights = pageHeights.map(height => (height/prevScale)*nextScale)
          const widths = pageWidths.map(width => (width/prevScale)*nextScale)
          // update store
          pdfMeta.pageHeights = heights
          pdfMeta.pageWidths = widths
          pdfMeta.prevScale = prevScale
          pdfMeta.scale = nextScale
        }
      }
    }

    setScaleAnchor(id: string, scaleAnchor: {x: number, y: number}|null) {
      const pdfMeta = this.getPdfMeta(id)
      if(pdfMeta) pdfMeta.scaleAnchor = scaleAnchor
    }

    setStaticPageHeights(id: string, pageHeights: number[]) {
      const pdfMeta = this.getPdfMeta(id)
      if(pdfMeta && pdfMeta.staticPageHeights.length === 0) pdfMeta.staticPageHeights = pageHeights
    }

    setStaticPageWidths(id: string, pageWidths: number[]) {
      const pdfMeta = this.getPdfMeta(id)
      if(pdfMeta && pdfMeta.staticPageWidths.length === 0) pdfMeta.staticPageWidths = pageWidths
    }

    updatePinchStartScale(id: string) {
      const pdfMeta = this.getPdfMeta(id)
      if(pdfMeta) pdfMeta.pinchStartScale = pdfMeta.scale
    }

}


const exportPdfMetaStore = new pdfMetaStore()
export default exportPdfMetaStore
